<template>
  <div> 
    <div class="valentine-event" v-if="!success"> 
      <div class="header">
        <div class="wrapper">
          <img src="../assets/images/logo_merck.png" alt="merck">
        </div>
      </div>
      <div class="event-main">
      </div>  
      <div class="event-main">
        <img src="../assets/images/event-main/img_valentine_event.png" alt="valentine event" class="block-img event-main__pc">
        <img src="../assets/images/event-main/img_valentine_event_mobile.jpg" alt="valentine event" class="block-img event-main__m">
      </div>  
      <div class="event-info event-info--01">
        <div class="wrapper"> 
          <div class="event-info__number">
            <img src="../assets/images/event-01/img_num_event1.png" alt="event1" class="block-img">
          </div>
          <div class="event-info__text event-text">
            연구실에서 발렌타인데이를 보내는 모습을<span class="break"></span> <span class="text-bold">사진</span>으로 보내주세요.<br/>
            쓸쓸한 솔로 발렌타인, 연구실에서 보내는 발렌타인 등<span class="break"></span> <span class="text-bold">어느 모습이든 좋아요 :)</span>
          </div>
          <div class="event-info-guide">
            <img src="../assets/images/event-01/img_event1_memo.png" alt="event guide" class="block-img">
          </div>
          <div class="event-info-ex">
            <img src="../assets/images/event-01/img_event1.png" alt="event1 ex" class="block-img"> 
          </div>
        </div>
      </div>
      <div class="event-gift">
        <div class="wrapper"> 
          <div class="event-gift__icon">
            <img src="../assets/images/event-gift/icon_event_gift.png" alt="gift" class="block-img">
          </div>
          <div class="event-gift__title">내 상품은 내가 결정한다!</div>
          <div class="event-gift__text">여러분의 열정에 발맞춰 <span class="text-bold text-pink">다양한 상패</span>가 기다리고 있습니다!</div>  
          <div class="event-gift__info">
            <img src="../assets/images/event-gift/img_event_prize_info.png" alt="2021.02.01 ~ 2021.02.07" class="block-img">
          </div>
          <div class="event-gift__img"> 
            <img src="../assets/images/event-gift/img_event_prizes.png" alt="열정상 찐쏠로상 비즈니스상 카운셀러상" class="block-img img-prize-list">
            <img src="../assets/images/event-gift/img_event_gift.png" alt="event gift" class="block-img img-prize-gift"> 
          </div>
          <div class="event-notice">
            <div class="notice">
              <div class="notice__title">주의 사항</div>
              <ul class="notice__list">
                <li class="list-item"><span>본 이벤트는 예고없이 조기 종료 될 수 있습니다.</span></li>
                <li class="list-item"><span>상황에 따라 이벤트 상품은 예고 없이 변경될 수 있습니다.</span></li>
                <li class="list-item"><span>경품 당첨 시, 타 경품 이벤트와 중복으로 지급되지 않습니다.<br/>(단, 진행 중인 이벤트에 중복 시 단일 이벤트의 당첨 확률 증가)</span></li>
                <li class="list-item">관련 문의는 <span class="vdn">rnadigital@merckgroup.com</span>으로 문의 바랍니다.</li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      <div class="event-info event-info--02">
        <div class="wrapper">
          <div class="event-info__number">
            <img src="../assets/images/event-02/img_num_event2.png" alt="event2" class="block-img">
          </div>
          <div class="event-title">참여만 해도 <span class="vdn">100%</span> 증정</div>
          <div class="event-info__text">수상 여부와 상관없이 연구실 여러분 모두가<br/>초콜릿을 받을 수 있도록 도와드릴게요.</div>
          <div class="event-info__text text-line">소속 정보만 입력하면 <span class="text-bold text-yellow">접수된 연구실 인원에 맞춰</span><br/>초콜릿 세트를 보내드려요.</div>
          <div class="button-kakao-box">
            <a href="https://sync.mlifescience.kr/frt/event?event_name=valentine" target="_blank" rel="noopener noreferrer" class="button-kakao-box__btn">
              <img src="../assets/images/event-02/icon_logo_kakao.png" alt="kakao" class="icon-kakao">카카오로 간편하게 소속입력 하기 
            </a>
          </div> 
          <div class="event-notice">
            <div class="notice">
              <div class="notice__title">주의 사항</div>
              <ul class="notice__list">
                <li class="list-item"><span class="text-bold text-yellow">학교 단위 참여자의 경우, 소속(학교) / 부서(학과) / 직함(ooo교수님 소속)으로 입력해주셔야 합니다.</span></li>
                <li class="list-item"><span class="vdn">PC</span>에서는 팝업 해제 / 모바일에서 더 원활하게 작동합니다.</li>
                <li class="list-item">소속 연구실이 명확하게 식별되지 않을 경우, 경품 지급에 제한이 있을 수 있습니다.</li> 
              </ul>
            </div> 
          </div>
          <div class="img-chocolate-box">
            <img src="../assets/images/event-02/img_choco_box.png" alt="chocolate box" class="block-img">
          </div> 
        </div>
      </div> 
      <div class="event-form" data-app>
        <div class="wrapper"> 
          <div class="event-form__title">
            <img src="../assets/images/event-form/img_form_title.png" alt="상품 주인공은 나야 나!" class="block-img">
          </div>
          <div class="form-layout"> 
            <div class="text-required">*표시는 필수 입력 사항입니다.</div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form"
            > 
              <label class="form__label"><span class="text-pink">*</span>이미지 첨부</label>
              <v-file-input 
                v-model="imageFile" 
                :rules="imageRules"
                accept="image/*" 
                class="form__input"
                show-size
              ></v-file-input> 
              <label class="form__label"><span class="text-pink">*</span>소속 기관 혹은 학교</label>
              <v-text-field
                v-model="company"
                :rules="companyRules"
                color="#EB3C96" 
                class="form__input"
                required
              ></v-text-field>
              <label class="form__label"><span class="text-pink">*</span>소속 부서/학과</label>
              <div class="label-sub text-pink">(학과 소속의 경우, “화학공학과 /ㅇㅇㅇ교수님 연구실 소속” 까지 입력해주세요.)</div>
              <v-text-field
                v-model="lab" 
                :rules="labRules"
                color="#EB3C96" 
                class="form__input"
                required
              ></v-text-field>
              <label class="form__label"><span class="text-pink">*</span>제출자 이름</label>
              <v-text-field
                v-model="name" 
                :rules="nameRules"
                color="#EB3C96" 
                class="form__input"
                required
              ></v-text-field>
              <label class="form__label"><span class="text-pink">*</span>연락처</label>
              <v-text-field
                v-model="phone" 
                :rules="phoneRules"
                color="#EB3C96" 
                class="form__input"
                required
              ></v-text-field>
              <label class="form__label"><span class="text-pink">*</span>이메일 주소</label>
              <v-text-field
                v-model="email" 
                :rules="emailRules"
                color="#EB3C96" 
                class="form__input form__input--email"
                required
              ></v-text-field>
              <v-checkbox
                v-model="check" 
                :rules="checkRules"
                color="#EB3C96" 
                class="form__checkbox" 
                hide-details  
                :ripple="false"
              >
                <template v-slot:label>
                  <div class="checkbox-label">확인합니다.</div>
                </template>
              </v-checkbox>
              <div class="form__link">업데이트 또는 머크로부터의 모든 수신을 원치 않으시면 <a href="http://bit.do/pipaural" target="_blank" rel="noopener noreferrer" class="link">링크</a>를 클릭해 주시기 바랍니다. <div class="span">(<a href="http://bit.do/fsWtw" target="_blank" rel="noopener noreferrer" class="link">머크 개인정보 처리방침</a>)</div></div>
              <v-btn 
                :disabled="!valid"
                color="#EB3C96"  
                @click="send" 
                depressed
                class="form__button"
                >참여하기</v-btn> 
            </v-form>
          </div>
        </div>
      </div>
      <div class="footer">
        <!-- 텍스트 -->
        <div class="contact">
          기타 문의사항은<span class="vdn"> rnadigital@merckgroup.com</span>으로<br/> 문의 부탁드립니다.
        </div> 
        <!-- sns 아이콘 -->
        <div class="sns">
          <a href="http://pf.kakao.com/_JQfwj" target="_blank">
            <img src="../assets/images/common/icon_kakao.png" alt />
          </a>
          <a href="https://www.youtube.com/channel/UC1q6842hLnsMG_0JdHs4KWw/" target="_blank">
            <img src="../assets/images/common/icon_youtube.png" alt />
          </a>
        </div>
      </div>
      <v-overlay :value="isLoading" :opacity="0.2"> 
        <v-btn icon>
          <v-progress-circular
            :size="50"
            color="#EB3C96"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </v-overlay> 
    </div>   
    <EventSendSuccess v-if="success" />
  </div>
</template>

<script>
  import EventSendSuccess from "./EventSendSuccess";  
  import axios from "axios";
  export default {
    components: {
      EventSendSuccess
    },
    data: () => ({ 
      success: false,
      isLoading: false,
      valid: true,
      imageFile: null,
      imageRules: [
        v => !!v || "이미지를 첨부해주세요.",
        v => !v || v.size < 10485760 || '최대 이미지 용량은 10 MB 입니다.'
      ],
      company: '',
      companyRules: [v => !!v || "소속 기관 혹은 학교명을 입력해주세요."],
      lab: '',
      labRules: [v => !!v || "소속 부서/학과명을 입력해주세요."],
      name: '',
      nameRules: [v => !!v || "이름을 입력해주세요."],
      phone: '',
      phoneRules: [
        v => !!v || "연락처를 입력해주세요.",
        v => /^[0-9]*$/.test(v) || "형식에 맞지않는 번호 입니다.",
        v => (v && v.length <= 11) || "형식에 맞지않는 번호 입니다."
      ],
      email: '',
      emailRules: [
        v => !!v || "이메일을 입력해주세요.",
        v => /.+@.+\..+/.test(v) || "이메일 주소 형식이 아닙니다."
      ],
      check: false,
      checkRules: [v=> !!v]
    }),
    methods: {
      send() { 
        if (this.$refs.form.validate()) {
          this.isLoading = true

          const formData = new FormData() 
          formData.append('company', this.company) 
          formData.append('lab', this.lab) 
          formData.append('name', this.name)
          formData.append('phone', this.phone)
          formData.append('email', this.email)
          if(this.imageFile) {
            formData.append('files', this.imageFile)
          }
          //  for (let key of formData.entries()) {
          //   console.log(`${key}`)
          // }

          axios
          .post("https://us-central1-merck-valentine.cloudfunctions.net/sendEvent", formData, {
            headers: {
              'Content-Type' : 'multipart/form-data'
            }
          })
          .then(() => { 
            this.isLoading = false;
            this.success = true;
            window.scrollTo(0, 0);
            this.reset();
          })
          .catch(() => { 
            this.isLoading = false;
            alert("발송을 실패했습니다. 확인 후 다시 전송해주세요.");
          })
          .finally(() => {
            this.isLoading = false;
          });
        } 
      },
      reset() {
        this.$refs.form.reset()
        this.imageFile = null
      },
    } 
  }
</script>

<style lang="scss" scoped>
  @import "../assets/variables.scss";
  .valentine-event {
    .wrapper {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
    }
    /* 이벤트 메인 */
    .event-main { 
      background: $lightpink;   
      &__pc {
        display: block;
      }
      &__m {
        display: none;
      }
    }  
    /* 이벤트 주의사항 */
    .event-notice {
      display: flex;
      align-items: center;
      justify-content: center;
      .notice {
        width: 100%;
        max-width: 520px;
        padding: 2.250em 2.500em;
        text-align: left; 
        border-radius: 1.250em;
        font-size: 16px;
        background: rgba(255,255,255,0.2);
        letter-spacing: -0.05em; 
        color: #ffffff;
        &__title {
          margin-bottom: 0.800em;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.05em; 
          line-height: 1;
        }
        &__list {
          list-style: none;
          padding: 0; 
          font-size: 16px;
          letter-spacing: -0.04em; 
          .list-item { 
            padding-left: 0.5em;
            word-break: keep-all; 
            line-height: 1.4;
            &:before {
              content: '-';
              display: inline-block;
              margin-left: -0.5em;
              width: 0.5em;
            } 
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    /* 이벤트 1, 이벤트 2 */
    .event-info {
      background-color: $pink; 
      .wrapper {
        text-align: center;
      } 
      &__number {
        img {
          width: 168px;
        }
      }
      &__text {
        font-size: 24px; 
        color: #ffffff;
        line-height: 1.4;
        letter-spacing: -0.05em; 
        word-break: keep-all;
      }
      &.event-info--01 {
        background-image: url('../assets/images/bg_icon_triangle_top.png'); 
        background-position: bottom; 
        background-size: 15px 8px;
        background-repeat: repeat-x;
        .wrapper {
          padding: 60px 20px 47px; 
        }
        .event-text {
          padding: 1.000em 0 1.167em; 
          .break {
            display: none;
          }
        }
        .event-info-guide { 
          margin-bottom: 10px;
        } 
      }
      &.event-info--02 { 
        .wrapper {
          max-width: 1600px;
          padding: 60px 20px;   
          background-image: url('../assets/images/event-02/bg_choco_left.png'), url('../assets/images/event-02/bg_choco_right.png');
          background-position: left calc(100% + 23px), right calc(100% + 10px); 
        }
        .event-title { 
          margin: 0.583em 0 0.792em;
          font-size: 48px;
          font-weight: 700;
          letter-spacing: -0.05em;
          line-height: 1;
          color: $purple;
        }
        .text-line {
          margin-top: 0.667em;
        }
        .button-kakao-box { 
          display: flex;
          justify-content: center;
          margin-top: 38px;
          margin-bottom: 60px;
          &__btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0.714em 2.000em;
            border-radius: 0.357em;
            background: $yellow; 
            font-size: 28px;
            letter-spacing: -0.05em;
            line-height: 1.45;
            color: $purple;
            text-decoration: none; 
            .icon-kakao {
              width: 40px;
              margin-right: 0.571em; 
            }
          } 
        } 
        .event-notice {
          margin-bottom: 60px;
          .notice { 
            &__list {
              letter-spacing: -0.055em;  
            }
          }
        }
      } 
    } 
    /* 이벤트 상품 안내 */
    .event-gift {
      background: $purple; 
      background-image: url('../assets/images/bg_icon_triangle_bottom.png'); 
      background-position: bottom; 
      background-size: 15px 8px;
      background-repeat: repeat-x;
      .wrapper {
        padding: 60px 20px 74px;
        text-align: center;
      }
      &__icon {
        margin-bottom: 28px;
      }
      &__title {
        margin-bottom: 0.688em;
        font-size: 48px;
        font-weight: 700;
        letter-spacing: -0.05em;
        line-height: 1.15;
        color: $pink;
      }
      &__text {
        margin-bottom: 0.923em;
        font-size: 26px;
        letter-spacing: -0.05em;
        line-height: 1.2;
        color: #ffffff;
      } 
      &__info {
        margin-bottom: 44px;
      }
      &__img {
        margin-bottom: 40px;
        .img-prize-list {
          margin-bottom: 40px;
        }
      }
      .event-notice {
        .notice {
          &__list {
            .list-item {
              &:nth-child(3) {
                line-height: 1.4;
              }
            }
          }
        }
      }
    }  
    /* 이벤트 폼 */
    .event-form {
      background: #ffffff; 
      .wrapper {
        padding: 60px 20px;
      }
      &__title {
        margin-bottom: 30px;
      }
      .form-layout {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        .text-required {
          margin-bottom: 1.667em;
          text-align: right; 
          font-size: 12px;
          font-weight: 700;
          letter-spacing: -0.05em;
          line-height: 1;
          color: $pink;
        }
        .form {
          &__label {
            font-size: 18px;
            letter-spacing: -0.05em;
            line-height: 1;
            color: $black; 
          }
          .label-sub {
            margin-top: 0.500em;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: -0.05em;
            line-height: 1;
            word-break: keep-all;
          }
          &__input {
            margin-top: 0;
            padding-top: 0; 
            margin-bottom: 22px;
            &--email {
              margin-bottom: 11px;
            }
          }
          &__checkbox { 
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 12px;
            .checkbox-label {
              margin-left: 0.500em;
              font-size: 16px;
              font-weight: 700;
              letter-spacing: -0.04em;
              color: #303030;
            }
            .error--text {
              margin-top: 30px;
              .checkbox-label {
                color: currentColor;
              }
            } 
          }
          &__link {
            font-size: 14px;
            color: #444444;
            letter-spacing: -0.04em;  
            line-height: 1.4;
            word-break: keep-all;
            .link {
              color: $black;
              font-weight: bold;
              text-decoration: underline;
            }
          }
          &__button {
            display: block;
            width: 100%;
            max-width: 360px;
            height: 80px;
            margin: 0 auto; 
            margin-top: 2.143em;
            border-radius: 0.357em;
            font-size: 28px;
            letter-spacing: -0.05em;
            color: #ffffff; 
          } 
        }
      }  
    }  
 
    @media (max-width:1200px) {  
      /* 이벤트 1, 이벤트 2 */
      .event-info {
        &__number {
          img {
            width: 148px;
          }
        }
        &__text {
          font-size: 22px;  
        }
        &.event-info--01 {
          .wrapper {
            padding: 50px 20px 36px; 
          }
          .event-info-guide {  
            margin-bottom: 8px;
            img {
              width: 500px;
            }
          }  
          .event-info-ex {
            img {
              width: 800px;
            }
          }
        }
        &.event-info--02 { 
          .wrapper { 
            padding: 50px 20px;   
            background-image: none; 
          }
          .event-title {  
            font-size: 44px; 
          } 
          .button-kakao-box {  
            margin-top: 28px;
            margin-bottom: 50px;
            &__btn { 
              font-size: 24px;  
            } 
          } 
          .event-notice {
            margin-bottom: 50px; 
          }
          .img-chocolate-box {
            img {
              width: 480px;
            }
          }
        } 
      } 
      /* 이벤트 상품 안내 */
      .event-gift { 
        .wrapper {
          padding: 50px 20px 64px;
          text-align: center;
        }
        &__icon {
          margin-bottom: 28px;
          img {
            width: 90px;
          }
        }
        &__title { 
          font-size: 46px; 
        }
        &__text { 
          font-size: 24px; 
        } 
        &__info {
          margin-bottom: 40px;
          img {
            width: 500px;
          }
        }
        &__img { 
          margin-bottom: 38px; 
          .img-prize-list {
            width: 320px;
            margin-bottom: 38px; 
          }
          .img-prize-gift {
            width: 630px;
          }
        } 
      } 
    }
    @media (max-width:800px) {
      .event-main {
        &__pc {
          display: none;
        }
        &__m {
          display: block;
        } 
      }  
      /* 이벤트 주의사항 */
      .event-notice { 
        .notice { 
          max-width: 65vw;   
          font-size: 2vw;
          &__title { 
            font-size: 2.5vw;
          }
          &__list {  
            font-size: 2vw;   
            .list-item { 
              &:not(:last-child) {
                margin-bottom: 1vw;
              }
            }
          }
        }
      }
       /* 이벤트 1, 이벤트 2 */
      .event-info {
        &__number {
          img {
            width: 18.5vw;
          }
        }
        &__text {
          font-size: 2.75vw;  
        }
        &.event-info--01 {
          background-size: 1.875vw 1vw;
          .wrapper {
            padding: 6.25vw 20px 4.5vw; 
          }
          .event-info-guide {  
            margin-bottom: 1vw;
            img {
              width: 62.5vw;
            }
          }  
          .event-info-ex {
            img {
              width: 100vw;
            }
          }
        }
        &.event-info--02 { 
          .wrapper { 
            padding: 6.25vw 20px;   
            background-image: none; 
          }
          .event-title {  
            font-size: 5.5vw; 
          } 
          .button-kakao-box {  
            margin-top: 3.5vw;
            margin-bottom: 6.25vw;
            &__btn { 
              font-size: 3vw;  
              .icon-kakao { 
                width: 5vw; 
              }
            } 
          } 
          .event-notice {
            margin-bottom: 6.25vw; 
          }
          .img-chocolate-box {
            img {
              width: 60vw;
            }
          }
        } 
      }
      /* 이벤트 상품 안내 */
      .event-gift { 
          background-size: 1.875vw 1vw;
        .wrapper {
          padding: 6.25vw 20px 8vw; 
        }
        &__icon {
          margin-bottom: 3.5vw;
          img {
            width: 11.25vw;
          }
        }
        &__title { 
          font-size: 5.75vw; 
        }
        &__text { 
          font-size: 4vw; 
        } 
        &__info {
          margin-bottom: 5vw;
          img {
            width: 62.5vw;
          }
        }
        &__img { 
          margin-bottom: 4.75vw; 
          .img-prize-list {
            width: 40vw;
            margin-bottom: 4.75vw; 
          }
          .img-prize-gift {
            width: 78.75vw;
          }
        } 
      } 
      /* 이벤트 폼 */
      .event-form { 
        .wrapper {
          padding: 7.5vw 20px;
        }
        &__title {
          margin-bottom: 24px;
          img {
            width: 320px;
          }
        } 
        .form-layout { 
          .text-required { 
            font-size: 11px; 
          }
          .form { 
            .label-sub { 
              font-size: 11px; 
            }
            &__input { 
              margin-bottom: 18px;
              &--email {
                margin-bottom: 10px;
              }
            }  
            &__button { 
              height: 8.9vw; 
              font-size: 3vw;  
            } 
          }
        } 
      }  
    }
    @media (max-width:576px) {
      /* 이벤트 주의사항 */
      .event-notice { 
        .notice { 
          max-width: 90vw;   
          font-size: 2vw;
          &__title { 
            font-size: 3.5vw;
          }
          &__list {  
            font-size: 3vw;   
          }
        }
      }
       /* 이벤트 1, 이벤트 2 */
      .event-info {
        &__number {
          img {
            width: 24vw;
          }
        }
        &__text {
          font-size: 3.75vw;  
        }
        &.event-info--01 {  
          .event-text { 
            .break {
              display: block;
            }
          }
          .event-info-guide {   
            img {
              width: 85vw;
            }
          }   
        }
        &.event-info--02 {  
          .event-title {  
            font-size: 6.25vw; 
          } 
          .event-info__text {
            font-size: 3.75vw;
          }
          .button-kakao-box {    
            margin-top: 4vw;
            margin-bottom: 6.5vw;
            &__btn { 
              font-size: 4.5vw;  
              .icon-kakao { 
                width: 5.5vw; 
              }
            } 
          }  
          .img-chocolate-box {
            img {
              width: 65vw;
            }
          }
        }   
      } 
      /* 이벤트 상품 안내 */
      .event-gift { 
        .wrapper {
          padding: 6.25vw 20px 8vw; 
        }
        &__icon {
          margin-bottom: 4vw;
          img {
            width: 12vw;
          }
        }
        &__title { 
          font-size: 6vw; 
        }
        &__text { 
          font-size: 3.75vw;
        } 
        &__info {
          margin-bottom: 5vw;
          img {
            width: 90vw;
          }
        }
        &__img { 
          margin-bottom: 4.75vw; 
          .img-prize-list {
            width: 58vw;
            margin-bottom: 5vw; 
          }
          .img-prize-gift {
            width: 80vw;
          }
        } 
      } 
      /* 이벤트 폼 */
      .event-form { 
        .wrapper {
          padding: 7.5vw 20px;
        }
        &__title {  
          img {
            width: 60vw;
          }
        } 
        .form-layout {  
          .form {  
            .label-sub { 
              font-size: 11px; 
              line-height: 1.4;
            }
            &__label {
              font-size: 14px;
            }
            &__input { 
              margin-bottom: 18px;
              &--email {
                margin-bottom: 10px;
              }
            }  
            &__checkbox {
              .checkbox-label {
                font-size: 14px; 
              }  
            }
            &__button {   
              height: 12.84vw;
              font-size: 4.5vw;  
              margin-top: 1.5em;
            }  
          }
        } 
      }  
    }
  } 
</style>