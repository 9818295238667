<template>
  <div>
    <div class="header">
      <div class="wrapper">
        <img src="../assets/images/logo_merck.png" alt="merck">
      </div>
    </div>
    <div class="event-send-success">
      <div class="wrapper">
        <div class="event-end-title">참여가 완료되었습니다.</div> 
        <img src="../assets/images/event-success/img_choco_box.png" alt="chocolate box" class="img-chocolate-box"> 
      </div> 
    </div> 
    <div class="footer">
      <!-- 텍스트 -->
      <div class="contact">
        기타 문의사항은<span class="vdn"> rnadigital@merckgroup.com</span>으로<br/> 문의 부탁드립니다.
      </div> 
      <!-- sns 아이콘 -->
      <div class="sns">
        <a href="http://pf.kakao.com/_JQfwj" target="_blank">
          <img src="../assets/images/common/icon_kakao.png" alt />
        </a>
        <a href="https://www.youtube.com/channel/UC1q6842hLnsMG_0JdHs4KWw/" target="_blank">
          <img src="../assets/images/common/icon_youtube.png" alt />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style scoped lang="scss">
@import "../assets/variables.scss";
.event-send-success { 
  background: #EB3C96;
  font-family: 'Noto Sans KR', Arial, Helvetica, sans-serif;
  text-align: center; 
  font-size: 16px;
  word-break: keep-all;
  overflow: hidden;
  width: 100%; 
  margin: 0 auto;
  img {
    width: auto;
    max-width: 100%;
    vertical-align: middle;
  }
  a {
    text-decoration: none;
  } 
  .wrapper {
    width: 100%; 
    max-width: 1600px;
    margin: 0 auto;
    padding: 6.250em 1.250em 6.875em;  
    padding: 100px 20px 110px;  
    background-image: url('../assets/images/event-success/img_choco_left01.png'), url('../assets/images/event-success/img_choco_left03.png'), url('../assets/images/event-success/img_choco_left04.png'), url('../assets/images/event-success/img_choco_right01.png'),  url('../assets/images/event-success/img_choco_right03.png'), url('../assets/images/event-success/img_choco_right04.png');
    background-position: left 37px,  90px calc(50% - 42px), left calc(100% - 57px), right 33px, calc(100% - 120px) calc(50% - 25px), calc(100% - 57px) calc(100% - 53px); 
    background-size: 77px,  80px, 74px, 94px,  100px, 78px;
    background-repeat: no-repeat;   
  }
  .event-end-title {
    margin-bottom: 1em;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 1;
    color: #503291;
  } 
  .img-chocolate-box {
    display: block;
    margin: 0 auto;
  } 
  @media (max-width:1600px) { 
    .wrapper {   
      background-position: -10px 37px,  80px calc(50% - 42px), -10px calc(100% - 57px), calc(100% + 10px) 33px, calc(100% - 120px) calc(50% - 25px), calc(100% + 10px) calc(100% - 53px); 
    } 
  } 
  @media (max-width:1200px) {
    .wrapper {   
      background-position: -10px 37px, 50px calc(50% - 42px), -10px calc(100% - 57px), calc(100% + 10px) 33px, calc(100% - 60px) calc(50% - 25px), calc(100% + 10px) calc(100% - 53px);
      background-size: 67px, 70px, 64px, 84px, 90px, 68px; 
    }  
  } 
  @media (max-width:800px) {
    .wrapper {  
      padding: 12.5vw 20px 13.75vw;  
      background-image: none;
    }      
    .event-end-title {  
      font-size: 44px; 
    }     
    .img-chocolate-box {
      width: 440px;
    } 
  }
  @media (max-width:600px) {
    .wrapper {
      background-image: none;
    }   
  }
  @media (max-width:500px) {   
    .event-end-title {  
      font-size: 8vw; 
    }    
    .img-chocolate-box {
      width: 72vw;
    }   
  } 
} 
</style>