<template> 
  <div>  
    <valentine-event/>
  </div> 
</template>

<script> 
import ValentineEvent from './components/ValentineEvent';

export default {
  name: 'App',

  components: { 
    ValentineEvent,
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" src="./assets/variables.scss"></style>
